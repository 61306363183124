import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import "./materials.scss"
import { window } from "browser-monads"
import Img from "gatsby-image"


export default function Materials() {
  const data = useStaticQuery(graphql`
  query {
    eggerImage: file(relativePath: { eq: "egger.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lamartyImage: file(relativePath: { eq: "lamarty.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kronospanImage: file(relativePath: { eq: "kronospan.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    evoglossImage: file(relativePath: { eq: "evogloss.webp" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mdfEnamelImage: file(relativePath: { eq: "mdf_enamel.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    woodVeneerImage: file(relativePath: { eq: "wood_veneer.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tssCleafImage: file(relativePath: { eq: "tss_cleaf.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hplImage: file(relativePath: { eq: "hpl.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    acrylineImage: file(relativePath: { eq: "acryline.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rauvisioBrilliantImage: file(relativePath: { eq: "rauvisio_brilliant.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rauvisioCrystalImage: file(relativePath: { eq: "rauvisio_crystal.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    acrylicPlasticImage: file(relativePath: { eq: "acrylic_plastic.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fenixImage: file(relativePath: { eq: "fenix.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stoneVeneerImage: file(relativePath: { eq: "stone_veneer.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solidWoodImage: file(relativePath: { eq: "solid_wood.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (

    <Layout>
      <SEO title="Материалы" />

      <h1 className="text-center">
        Материалы, которые мы используем в своей работе
    </h1>
      <Container>

        <div className="card-group vgr-cards">
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.eggerImage.childImageSharp.fluid} />

            </div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ЛДСП Egger</h4>
              <p className="card-text">Компания ЭГГЕР
              (EGGER) по праву считается одним из мировых лидеров в производстве
              продукции из древесины. Плиты ЛДСП, представленные компанией на
              российском рынке давно стали эталоном качества для производителей
            мебели.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.lamartyImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ЛДСП Lamarty</h4>
              <p class="card-text">Высококачественные и экологичные ламинированные плиты Российского
              производства. Процесс производства ЛДСП Lamarty полностью
              автоматизирован, а качество обеспечивается полным контролем
              процессов на всех этапах высококвалифицированным персоналом и
            современным оборудованием.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.kronospanImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ЛДСП Kronospan</h4>
              <p className="card-text">Завод
              Кроношпан, расположенный в г. Егорьевск, выпускает в месяц около 2
              500 000 квадратных метров ламинированной древесностружечной плиты
            ЛДСП. На производстве применяется новейшее оборудование.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.evoglossImage.childImageSharp.fluid} />
            </div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">EVOGLOSS</h4>
              <p className="card-text">Прочная и надёжная основа из МДФ, покрытая декоративным ПВХ толщиной 0,35 мм. Это покрытие отличается высокой степенью зеркальности и ярким насыщенным цветом, а также устойчивостью к царапинам и к воздействию органических продуктов и бытовой химии.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.mdfEnamelImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ФАСАДЫ ИЗ МДФ В ЭМАЛИ</h4>
              <p class="card-text">Один из наиболее популярных способов отделки мебели из МДФ — покраска. Она не только придает изделиям необходимый цвет, но и позволяет добиться интересных визуальных эффектов. При этом крашеные поверхности благодаря качественным покрытиям приобретают водоотталкивающие и даже огнезащитные свойства, существенно увеличивая срок службы мебели.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.woodVeneerImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ИЗДЕЛИЯ ИЗ ШПОНА ДЕРЕВА</h4>
              <p className="card-text">Можем предложить Вам изделия из натурального шпона в виде строганого, лущеного или пиленого шпона (около 100 пород) толщиной от 0,6 до 11,5 мм., либо файн-лайн — великолепную имитацию популярных пород древесины с использованием натурального дерева в виде реконструированного шпона определенных размеров, со стабильными оттенками и текстурой. Фасады в шпоне могут быть выполнены из МДФ или ДСП (по желанию клиента), в матовом или глянцевом исполнении.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.tssCleafImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ЭКО-ПЛИТЫ TSS CLEAF</h4>
              <p className="card-text">Современное поколение экологически чистых отделочных материалов для производства мебели и дизайна интерьеров. Плиты TSS (thermo structured surface — термо-структурированная поверхность), производятся технологичным способом прессования слоистых пластиков на древесные плиты из ДСП и МДФ.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.hplImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">HPL ПЛАСТИК</h4>
              <p className="card-text">(High pressure laminate) — материал, произведенный под высоким давлением. Отличается особым составом: на 70 % состоит из бумаги (целлюлозы) и на 30 % из поликонденсированных термореактивных смол. Имеет монолитную декоративную поверхность. Не содержит и не выделяет вредных веществ даже при нагревании.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.acrylineImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">ACRYLINE</h4>
              <p className="card-text">Представляет собой плиту МДФ, облицованную суперглянцевым акриловым пластиком. Идеально подходит тем, кто стремится найти золотую середину между стильным, качественным материалом и простотой и удобством использования.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.rauvisioBrilliantImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">RAUVISIO BRILLIANT</h4>
              <p className="card-text">Новый высокоглянцевый пластик для отделки вертикальных мебельных поверхностей позволяет создать изысканный дизайн во всей его многогранности. За счет сочетания поверхности с соответствующими кромочными материалами обеспечивается эффект монолитности.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.rauvisioCrystalImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">СТЕКЛОЛАМИНАТ RAUVISIO CRYSTAL</h4>
              <p className="card-text">Инновационная формула материала полностью компенсирует недостатки натурального стекла за счет комбинации его преимуществ со свойствами термопластичного ламината. Стеклоламинат сочетает в себе оптику натурального стекла и прочность полимера. Стеклоламинат не боится царапин и износа.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.acrylicPlasticImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">АКРИЛОВЫЙ ПЛАСТИК</h4>
              <p className="card-text">Материал уже давно зарекомендовал себя как важный элемент в отделке пространства. Пластик отличается ударопрочностью — в 5 раз выше, чем у стекла; материал пропускает до 70% УФ-лучей и совершенно нечувствителен к их воздействию.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.fenixImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">FENIX</h4>
              <p className="card-text">Умный НАНОтехнологичный суперматовый материал для интерьерного дизайна. Инновационная самовосстанавливающаяся поверхность с эффектом soft touch.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.stoneVeneerImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">КАМЕННЫЙ ШПОН</h4>
              <p className="card-text">Тонкий слой камня сланца, слюды, известняка, песчаника или мрамора. Используется для отделки стен и полов внутренних помещений, фасадов зданий, каминов, мебели, в том числе и сложных изогнутых поверхностей.</p>

            </div>
          </div>
          <div className="card py-3">
            <div className="card-img-body">
              <Img fluid={data.solidWoodImage.childImageSharp.fluid} /></div>
            <div className="card-body">
              <h4 className="card-title h4 w-75">МАССИВ ДЕРЕВА</h4>
              <p className="card-text">Обработанные древесные полотна, доски и брусы — может изготавливаться разными способами, но он ВСЕГДА делается из дерева. Изделия из массива дерева по праву считаются самыми качественными, экологически чистыми и дорогими.</p>

            </div>
          </div>
        </div>

      </Container>
    </Layout>
  )

}