/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Navbar from "./navbar"
import CookiesAlert from "../components/cookies-info-alert"
import FloatingContacts from "../components/floating-contact-icons"
import "./layout.scss"

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div style={{ minHeight: "100vh", backgroundColor: "#FFF" }}>
          <Navbar />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: "100%",
              padding: `0px 1.0875rem 1.45rem`,
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingTop: 100,
            }}
          >
            <main style={{ marginBottom: "195px", }}>{children}</main>
            <footer className="mb-5 pt-5 px-3" style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "auto",
              maxHeight: "195px",
              backgroundColor: "#fff",
            }}>
              <p className="mr-3 font-weight-normal mb-0 text-sm-left trxt-md-center text-lg-center text-xl-center">
                © {new Date().getFullYear()}, {data.site.siteMetadata.title}
                .{` `}Информация, представленная на сайте, не является публичной офертой или предложением сотрудничества.
              </p>

            </footer>
            <CookiesAlert />
            <FloatingContacts />
            {/* <div
              style={{
                position: "fixed",
                right: "0px",
                zIndex: "800",
                bottom: "0px",
              }}
              className="d-flex flex-column p-2 bg-white"
            >
              <a href="tel:+7-921-908-0881" className="mb-2">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    color: "#000000CC",
                  }}
                  className="fa-2x"
                />
              </a>
              <a href="https://wa.me/79219080881" className="mb-2">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  style={{
                    color: "#000000CC",
                  }}
                  className="fa-2x"
                />
              </a>
              <a href="https://www.instagram.com/imperia_st.meb/" className="mb-2">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{
                    color: "#000000CC",
                  }}
                  className="fa-2x"
                />
              </a>
              <a href="https://vk.com/club95884987">
                <FontAwesomeIcon
                  icon={faVk}
                  style={{
                    color: "#000000CC",
                  }}
                  className="fa-2x"
                />
              </a>
            </div> */}
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
